* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
/* html {
  font-family: "Montserrat", sans-serif;
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
} */
body {
  background: #eee;
  padding: 0 16px;

  background-color: #13c0f5b0;
  /* background-image: url("https://www.transparenttextures.com/patterns/cubes.png"); */
}

header {
  text-align: center;
   font-size: calc(4px + 1vmin);
  
  background-color: #13c0f5d0;
  margin: auto -16px;
  padding: 16px 32px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);


}

header h1 {
  color: #fff;
  /* font-family: "McLaren", cursive; */
  font-weight: 200;
}


.placeGroup {
  /* height: 5em; */
  margin-top: 1em;

  border-style: solid;
  border-width: 0px;
  //border-color: rgb(118, 145, 20);
  border-color: black;
  border-top-width: 2px;
  /* margin-bottom: 0  px; */
}


footer {
  /* position: absolute; */
  text-align: center;
  bottom: 0;
  width: 100%;
  height: 1.0rem;
}

footer p {
  color: #ccc;
}

