* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.CountySelector {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 4px 8px #ccc;
  padding: 10px;
  width: 200px;
  margin: 16px;
  /* float: left; */
}
.CountySelector h1 {
  font-size: 1.1em;
  margin-bottom: 6px;
}
.CountySelector p {
  font-size: 1.1em;
  margin-bottom: 10px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.CountySelector button {
  position: relative;
  float: right;
  margin-right: 10px;
  /* color: #f5ba13; */
  border: none;
  width: 36px;
  height: 36px;
  cursor: pointer;
  outline: none;
}
