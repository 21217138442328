* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.CovidChart {
  /* height: 5em; */
  margin-top: 1em;

  border-style: solid;
  border-width: 0px;
  border-color: black;
  border-top-width: 2px;
  /* margin-bottom: 0  px; */
}

.CovidChart button {
  font-size: 0.6em;
  /* position: relative;
  margin-left: 10px; */
  /* float: left; */
  background-color: floralwhite;
  color: black;
  /* border: none;
  width: 36px;
  height: 36px;
  cursor: auto;
  outline: none; */
}
